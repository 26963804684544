<template>
    <div class="data-page-content rowFlex" style="flex-flow: column; justify-content: center; align-items: center; margin-left: 8px;">
        <div class="rowFlex" style="margin-bottom: 20px;">
            <div class="rowFlex top">
                <div class="data-item-wrap">
                    <DataItemPanelLeftBorder />
                    <div class="personal-type-panel buildingUsers">
                        <div class="building-data">
                            <div class="people-num">
                                <svg class="icon" aria-hidden="true" style="font-size: 120px;">
                                    <use xlink:href="#icon-menulouneiyonghu_ruzhuqiyeyong"></use>
                                </svg>
                                <div class="num">{{ userCount }}人</div>
                            </div>
                            <div class="enterprise">
                                <svg class="icon" aria-hidden="true" style="font-size: 120px;">
                                    <use xlink:href="#icon-menulouneiyonghu_ruzhuqiyeyong"></use>
                                </svg>
                                <div class="num">
                                    {{ lesseeCount }}家
                                </div>
                            </div>
                        </div>
                        <div class="data-item-title">楼内用户性别/年龄占比</div>
                        <div class="sex">
                            <div class="man">
                                <div class="list">
                                    <svg class="icon" v-for="index in 10" :key="index" style="font-size: 24px;" >
                                        <use v-if="index <= (genderRate.manRate / 10)" xlink:href="#icon-menunan"></use>
                                        <use v-else xlink:href="#icon-menuwhiteMan-copy"></use>
                                    </svg>
                                </div>
                                <div class="proportion">
                                    <p class="percentage">{{genderRate.manRate}}%</p>
                                    <p class="text">男性用户</p>
                                </div>
                            </div>
                            <div class="woman">
                                <div class="list">
                                    <svg class="icon" v-for="index in 10" :key="index" style="font-size: 24px;" >
                                        <use v-if="index <= (genderRate.femaleRate / 10)" xlink:href="#icon-menunv"></use>
                                        <use v-else xlink:href="#icon-menuwhiteWoman-copy"></use>
                                    </svg>
                                </div>
                                <div class="proportion">
                                    <p class="percentage">{{genderRate.femaleRate}}%</p>
                                    <p class="text">女性用户</p>
                                </div>
                            </div>
                            <div class="chart">
                                <div class="chart-item" v-for="(item, index) in ageInterList" :key="index">
                                     <div class="chart-item-info">
                                         <span class="square"></span>
                                         <span>{{ageRange[item.ageRange]}}</span>
                                         <span>{{item.ageCount}}人</span>
                                     </div>
                                     <div class="line">
                                         <div class="line-bg">
                                             <div class="line-color" :style="'width:' + item.countRate * ageMagnification + '%'"></div>
                                             <div class="num">{{item.countRate}}%</div>
                                         </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="data-item-wrap">
<!--                    <div class="data-region-background" @click="to3D" style="position: relative;">-->
                    <div class="data-region-background" style="position: relative;">
<!--                         <img class="bg" src="../../assets/bigData/one/regionBackground.png" alt=""> -->
                        <img :src="regionImg" style="width: 900px; height: 544px; position: absolute; left: 43px; top: 30px;" alt="" />
                    </div>
                </div>
                <div>
                    <div class="data-item-wrap" style="margin-right: 0;">
                        <DataItemPanelLeftBorder />
                        <div class="personal-type-panel lease-situation">
                            <div class="data-item-title">租赁情况</div>
                            <div class="lease-situation-chart">
                                <div class="info">
                                    <div class="room">
                                        <p class="num">{{regionRoomCount}}个</p>
                                        <p class="text">总房间数</p>
                                    </div>
                                    <div class="area">
                                        <p class="num">{{sumArea}}m²</p>
                                        <p class="text">总面积</p>
                                    </div>
                                </div>
                                <div id="tenant"></div>
                            </div>
                        </div>
                    </div>
                    <div class="data-item-wrap" >
                        <DataItemPanelLeftBorder style="height: 300px;"  />
                        <div class="personal-type-panel building-rental-rate" style="height: 300px;padding-top: 15px">
                            <video muted="muted" loop="loop" autoplay="autoplay" src="https://cosmoss-static.oss-cn-shanghai.aliyuncs.com/video/dashujuzhanshideshipin.mp4"    >
                                您的浏览器不支持视频播放
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rowFlex">
            <div class="data-item-wrap">
                <DataItemPanelLeftBorder />
                <div class="personal-type-panel enterprise-staff">
                    <div class="data-item-title">企业员工数排名</div>
                    <div id="staff"></div>
                </div>
            </div>
            <div class="data-item-wrap">
                <DataItemPanelLeftBorder />
                <div class="personal-type-panel lease-area">
                    <div class="data-item-title">企业使用面积比例图</div>
                    <div class="leaseArea">
                        <div class="oneCol">
                            <div class="bg-color-purple">
                                <div v-if="spaceList[0]">
                                    <p>{{spaceList[0] ? spaceList[0].companyName : ''}}</p>
                                    <p>{{spaceList[0] ? spaceList[0].spaceSum : ''}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-blue">
                                <div v-if="spaceList[1]">
                                    <p>{{spaceList[1] ? spaceList[1].companyName : ''}}</p>
                                    <p>{{spaceList[1] ? spaceList[1].spaceSum : ''}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="twoCol">
                            <div class="bg-color-green">
                                <div v-if="spaceList[2]">
                                    <p>{{spaceList[2] ? spaceList[2].companyName : '-'}}</p>
                                    <p>{{spaceList[2] ? spaceList[2].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-red">
                                <div v-if="spaceList[3]">
                                    <p>{{spaceList[3] ? spaceList[3].companyName : '-'}}</p>
                                    <p>{{spaceList[3] ? spaceList[3].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-yellow">
                                <div v-if="spaceList[4]">
                                    <p>{{spaceList[4] ? spaceList[4].companyName : '-'}}</p>
                                    <p>{{spaceList[4] ? spaceList[4].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="threeCol">
                            <div class="bg-color-red">
                                <div v-if="spaceList[5]">
                                    <p>{{spaceList[5] ? spaceList[5].companyName : '-'}}</p>
                                    <p>{{spaceList[5] ? spaceList[5].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-yellow">
                                <div v-if="spaceList[6]">
                                    <p>{{spaceList[6] ? spaceList[6].companyName : '-'}}</p>
                                    <p>{{spaceList[6] ? spaceList[6].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-green">
                                <div v-if="spaceList[7]">
                                    <p>{{spaceList[7] ? spaceList[7].companyName : '-'}}</p>
                                    <p>{{spaceList[7] ? spaceList[7].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-purple">
                                <div v-if="spaceList[8]">
                                    <p>{{spaceList[8] ? spaceList[8].companyName : '-'}}</p>
                                    <p>{{spaceList[8] ? spaceList[8].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-blue">
                                <div v-if="spaceList[9]">
                                    <p>{{spaceList[9] ? spaceList[9].companyName : '-'}}</p>
                                    <p>{{spaceList[9] ? spaceList[9].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="fourCol" style="border-right-width: 1; border-right: 1px solid #fff;">
                            <div class="bg-color-blue">
                                <div v-if="spaceList[10]">
                                    <p>{{spaceList[10] ? spaceList[10].companyName : '-'}}</p>
                                    <p>{{spaceList[10]? spaceList[10].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-purple">
                                <div v-if="spaceList[11]">
                                    <p>{{spaceList[11] ? spaceList[11].companyName : '-'}}</p>
                                    <p>{{spaceList[11]? spaceList[11].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-red">
                                <div v-if="spaceList[12]">
                                    <p>{{spaceList[12] ? spaceList[12].companyName : '-'}}</p>
                                    <p>{{spaceList[12]? spaceList[12].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-yellow">
                                <div v-if="spaceList[13]">
                                    <p>{{spaceList[13] ? spaceList[13].companyName : '-'}}</p>
                                    <p>{{spaceList[13]? spaceList[13].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="bg-color-green">
                                <div v-if="spaceList[14]">
                                    <p>{{spaceList[14] ? spaceList[14].companyName : '-'}}</p>
                                    <p>{{spaceList[14]? spaceList[14].spaceSum : '-'}}m²</p>
                                </div>
                                <div v-else>
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="fiveCol">
                            <div>
                                <p>剩余{{surplusTotal}}家企业</p>
                                <p>{{surplusTotalSpace}}m²</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-item-wrap" style="width: 400px">

            </div>
            <div class="data-item-wrap bottom-right-p">
                <DataItemPanelLeftBorder />
                <div class="personal-type-panel building-rental-rate">
                    <div class="data-item-title">楼栋出租率排行前五</div>
                    <div class="rental-rate">
                        <div class="chart">
                            <div class="chart-item" v-for="(item, index) in rentalRate" :key="index" style="margin-bottom: 27px">
                                <div class="chart-item-info">
                                    <span class="square"></span>
                                    <span>{{item.buildingName}}</span>
                                    <span>{{item.buildingRoomCount}}间</span>
                                    <span>{{item.buildingRoomSpaceSum}}m²</span>
                                </div>
                                <div class="line">
                                    <div class="line-bg">
                                        <div class="line-color" :style="'width:' + item.buildingRoomOccRate + '%'"></div>
                                    </div>
                                    <div class="num">{{item.buildingRoomOccRate}}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import DataItemPanelLeftBorder from "@/views/BigData/DataItemPanelLeftBorder";

import {
    queryRegionInfoUrl,
    queryTenantRoomAreaUrl,
    queryUserCountUrl,
    queryLesseeCountUrl,
    queryGenderRateUrl,
    queryAgeIntervalUrl,
    queryCompanyCountUrl,
    queryBuildingOccRateUrl,
    queryIdleRateUrl,
    queryLesseeSpaceUrl,
} from '@/requestUrl';
import { initWs } from "@/views/BigData/tools";

let charts = [];
export default {
    name: 'BigDataPageOne',
    components: {
        DataItemPanelLeftBorder,
    },
    data() {
        return {
            regionImg: '',
            // 园区code
            regionCode: this.$vc.getCurrentRegion().code,
            socket: null,
            // 楼内用户
            userCount: null,
            // 入驻企业
            lesseeCount: null,
            // 男女性别比例
            genderRate: {
                manRate: null,
                femaleRate: null,
            },
            // 年龄比例
            ageInterList: [],
            // 楼栋出租率
            rentalRate: [],
            // 年龄区间
            ageRange: {
                0: '未知',
                1: '16-20岁',
                2: '21-30岁',
                3: '31-40岁',
                4: '41-50岁',
                5: '50岁以上',
            },
            // 宽度最大倍率
            ageMagnification: 0,
            // 总面积
            sumArea: null,
            // 园区房间总数
            regionRoomCount: null,
            // 企业租赁面积排名
            spaceList: [],
            // 剩余公司数
            surplusTotal: null,
            // 剩余公司占地总面积
            surplusTotalSpace: null,
        }
    },
    mounted() {
        this.getRegionInfo();
        this.getAgeRtio();
        this.initPage();
    },
    beforeDestroy() {
        charts.forEach(chart => chart.dispose());
        charts = [];
        if (this.socket) {
            this.socket.close(3000, '页面关闭');
        }
    },
    methods: {
        // 查询楼内用户
        async getUserCount() {
            let res = await this.$fly.get(queryUserCountUrl,{
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            this.userCount = res.data;
        },
        // 查询入驻企业
        async getLesseeCount() {
            let res = await this.$fly.get(queryLesseeCountUrl, {
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            this.lesseeCount = res.data;
        },
        // 查询管理区
        async getRegionInfo() {
            let res = await this.$fly.get(queryRegionInfoUrl, {
                code: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            this.regionImg = res.data.mapImgUrl;
        },
        // 获取性别比例
        async getGenderRate() {
            let res = await this.$fly.get(queryGenderRateUrl, {
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            this.genderRate = res.data;
        },

        // 获取年龄比例
        async getAgeRtio() {
            let res = await this.$fly.get(queryAgeIntervalUrl, {
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            let maximumProportion = 0;
            this.ageInterList = res.data.map(item => {
                let itemCopy = {...item};
                itemCopy.countRate = 0;
                item.countRate > maximumProportion ? maximumProportion = item.countRate : maximumProportion;
                return itemCopy;
            })
            this.ageMagnification = 100 / maximumProportion;
            let timeBar = setTimeout(() => {
                this.ageInterList = res.data;
                clearTimeout(timeBar);
            })
        },
        // 获取企业租赁面积排名
        getLesseeAreaRanking() {
            this.$fly.get(queryLesseeSpaceUrl, {
                regionCode: this.regionCode,
            })
            .then(res => {
                if (res.code != 0) {
                    return;
                }
                this.spaceList = res.data.sapceList;
                this.surplusTotal = res.data.surplusTotal;
                this.surplusTotalSpace = res.data.surplusTotalSpace;
            })

        },
        // 获取楼栋出租率
        async getRentalRate() {
            let res = await this.$fly.get(queryBuildingOccRateUrl, {
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            console.log('楼栋出租率');
            console.log(res.data);
            this.rentalRate = res.data.map(item => {
                let itemCopy = {...item};
                itemCopy.buildingRoomOccRate = 0;
                return itemCopy;
            })
            let timeBar = setTimeout(() => {
                this.rentalRate = res.data;
                clearTimeout(timeBar);
            })
        },
        // 获取租赁面积
        queryTenantRoomArea() {
            return this.$fly.get(queryTenantRoomAreaUrl, {
                regionCode: this.regionCode,
            })
                .then(res => {
                    if (res.code !== 0) {
                        return ;
                    }
                    console.log('租赁情况');
                    console.log(res.data);
                    this.sumArea = res.data.sumArea;
                    this.regionRoomCount = res.data.regionRoomCount;
                    let noVacancyOdds = res.data.noVacancyOdds.slice(0, res.data.noVacancyOdds.indexOf('.'));
                    let vacancyOdds = res.data.vacancyOdds.slice(0, res.data.noVacancyOdds.indexOf('.'));
                    console.log(noVacancyOdds, vacancyOdds)
                    // 租赁情况
                    return this.initEChart('tenant', {
                        color: ['rgba(2, 249, 193, 1)',  'rgba(35, 132, 255, 1)'],
                        series: [
                            {
                                name: '租赁情况',
                                type: 'pie',
                                radius: ['30', '60%'],
                                itemStyle: {
                                    borderWidth: 6,
                                    borderCap: 'butt',
                                    borderColor: '#021659'
                                },
                                label: {
                                    formatter: '{b}{d}%',
                                    width: 80,
                                    height: 200,
                                    align: 'left',
                                    overflow: 'break',
                                    fontSize: 12,
                                    show: true,
                                    position: 'outside',
                                },
                                labelLine: {
                                    show: true,
                                    /*length: 30,
                                    length2: 20,*/
                                },
                                data: [
                                    {value: noVacancyOdds, name: '空置率', label: {color: 'rgba(2, 249, 193, 1)'}},
                                    {value: vacancyOdds, name: '出租率', label: {color: 'rgba(35, 132, 255, 1)'}},
                                ],
                            }
                        ]
                    })
                })
        },
        // 获取企业员工数排名
        getStaffNum() {
            return this.$fly.get(queryCompanyCountUrl, {
                regionCode: this.regionCode,
            })
            .then(res => {
                if (res.code != 0) {
                    return;
                }
                let lesseeName = [];
                let lesseeStaffNum = [];
                res.data.forEach(item => {
                    let companyName = item.companyName.slice(0, 3) + '∗∗∗∗' + item.companyName.slice(item.companyName.length - 4);
                    lesseeName.push(companyName);
                    lesseeStaffNum.push(item.companyUserCount)
                })
                return this.initEChart('staff', {
                    grid: {
                        top: 0,
                        left: 0,
                        right: 40,
                        bottom: -20,
                        containLabel: true
                    },
                    xAxis: {
                        show: false
                    },
                    yAxis: {
                        type: 'category',
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#fff'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        data: lesseeName,
                        inverse: true,
                        max: 10,
                    },
                    series: {
                        type: 'bar',
                        data: lesseeStaffNum,
                        datasetIndex: 1,
                        // // barWidth: 14,
                        label: {
                            show: true,
                            position: 'right',
                            color: '#fff',
                        }
                    },
                    color: {
                        type: 'linear',
                        x: 1,
                        y: 1,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: 'rgba(148, 253, 235, 0.8)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(91, 119, 249, 0.8)' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    }
                })
            })
        },
        async initPage() {
            if (this.socket) {
                this.socket.close();
                this.socket = null;
            }
            Promise.all([
                this.queryTenantRoomArea(),
                this.getStaffNum(),
                this.getUserCount(),
                this.getLesseeCount(),
                this.getAgeRtio(),
                this.getRentalRate(),
                this.getGenderRate(),
                this.getLesseeAreaRanking(),
            ])
            .then(allRes => {
                const [tenantRoomAreaChart, staffNumChart, vacancyRateChart] = allRes;
                this.socket = initWs({
                    messageHandler: res => {
                        if (res.data === '心跳' || res.data === '连接开始') {
                            return;
                        }
                        let data = JSON.parse(res.data);
                        console.log(data ,1111111111);
                        try {
                            const dataTypeHandlers = {
                                queryRoomArea: () => {
                                    this.sumArea = data.data.data.sumArea;
                                    this.regionRoomCount = data.data.data.regionRoomCount;
                                    let noVacancyOdds = data.data.data.noVacancyOdds.slice(0, res.data.noVacancyOdds.indexOf('.'));
                                    let vacancyOdds = data.data.data.vacancyOdds.slice(0, res.data.noVacancyOdds.indexOf('.'));
                                    tenantRoomAreaChart.setOption({
                                        series: [
                                            {
                                                name: '租赁情况',
                                                type: 'pie',
                                                radius: ['40%', '70%'],
                                                itemStyle: {
                                                    borderWidth: 6,
                                                    borderCap: 'butt',
                                                    borderColor: '#021659'
                                                },
                                                label: {
                                                    formatter: '{b}{d}%',
                                                    width: 80,
                                                    height: 200,
                                                    align: 'left',
                                                    overflow: 'break',
                                                    fontSize: 12,
                                                    show: true,
                                                    position: 'outside',
                                                },
                                                labelLine: {
                                                    show: true,
                                                },
                                                data: [
                                                    {value: noVacancyOdds, name: '空置率', label: {color: 'rgba(2, 249, 193, 1)'}},
                                                    {value: vacancyOdds, name: '出租率', label: {color: 'rgba(35, 132, 255, 1)'}},
                                                ],
                                            }
                                        ]
                                    })
                                },
                                lesseeCount: () => {
                                    let source = data.data.data.map(item => {
                                        let companyName = item.companyName.slice(0, 3) + '****' + item.companyName.slice(item.companyName.length - 4);
                                        return [companyName, item.companyUserCount];
                                    })
                                    staffNumChart.setOption({
                                        dataset: [
                                            {
                                                dimensions: ['name','score'],
                                                source
                                            },
                                            {
                                                transform: {
                                                    type: 'sort',
                                                    config: { dimension: 'score', order: 'asc' }
                                                }
                                            }
                                        ],
                                    })
                                },
                                idelRate: () => {
                                    let dateList = data.data.data.map(item => {
                                        return item.month.slice(5,7) + '月';
                                    })
                                    let idelRateList = data.data.data.map(item => {
                                        return item.idelRate;
                                    })
                                    vacancyRateChart.setOption({
                                        xAxis: {
                                            type: 'category',
                                            data: dateList,
                                            axisLine: {
                                                lineStyle: {
                                                    color: '#fff',
                                                }
                                            },
                                            axisLabel: {
                                                color: '#fff',
                                            }
                                        },
                                        series: [
                                            {
                                                data: idelRateList,
                                                type: 'line'
                                            }
                                        ]
                                    })
                                },
                                genderRate: () => {
                                    this.genderRate = data.data.data;
                                },
                                buildingCount: () => {
                                    this.userCount = data.data.data;
                                },
                                ageRange: () => {
                                    this.ageInterList = data.data.data;
                                },
                                occupancyRate: () => {
                                    this.rentalRate = data.data.data;
                                },

                            }
                            try {
                                dataTypeHandlers[data.method]();
                                console.log(data.method, '成功')
                            } catch (err) {
                                console.log(data.method, '错误', err);
                            }
                        } catch (err) {
                            console.log(err, '解析失败');
                        }
                    }
                })
            })
        },
        initEChart(elName, options) {
            const myChart = echarts.init(document.getElementById(elName));
            myChart.setOption(options);
            charts.push(myChart);
            return myChart;
        },
        to3D() {
            this.$router.push({path: 'SmartPark'});
        }
    }
}
</script>

<style lang="stylus" scoped>
@import "./common.styl";

.data-item-wrap
    @extends .rowFlex
    align-items flex-start
    &:not(:last-of-type)
        margin-right 20px

.data-region-background
    background-image url("./images/oneBg1.png")
    background-size 100% 100%
    width 980px
    height 617px
    .bg
        width 100%
        height 100%
.building-data
    display flex
    justify-content space-around
    margin-bottom 20px
    .people-num
        width 120px
        height 100px
        margin-top 5px
        position relative
        &:before
            content '楼内用户'
            color #1987F1
            position absolute
            font-size 18px
            position absolute
            z-index 10
            left 50%
            transform translateX(-50%)
            bottom 0
            white-space nowrap
        .num
            color #FFD301
            font-size 24px
            position absolute
            z-index 10
            top 50%
            left 50%
            transform translate(-50%, -50%)
            white-space nowrap

    .enterprise
        @extends .people-num
        &:before
            content '入驻企业'
.sex
    .man
        margin-bottom 10px
        display flex
        align-items center
        .list
            white-space nowrap
            .icon
                margin-right 6px
        .proportion
            text-align center
            .percentage
                font-size 14px
                color #00E6FF
                margin-bottom 0px
            .text
                font-size 12px
                color #fff
                margin-bottom 0px
    .woman
        @extends .man
        .proportion
            .percentage
                color #FF5A5A
    .chart
        .chart-item
            margin-bottom 15px
            .chart-item-info
                display flex
                align-items center
                margin-bottom 4px
                span
                    margin-right 20px
                    color #fff
                    font-size 14px
                .square
                    display inline-block
                    width 10px
                    height 10px
                    margin-right 15px
                    background-color #02F9C1
            .line
                width 100%
                height 6px
                .line-bg
                    width 100%
                    height 100%
                    display flex
                    align-items center
                    .line-color
                        height 100%
                        background-color  #02F9C1
                        border-radius 3px
                        margin-right 10px
                        transition all 1s linear
                    .num
                        color #1AC9FF
                        font-size 14px
.rental-rate
    @extends .sex
    .chart
        .chart-item
            margin-bottom 30px
            .chart-item-info
                .square
                    background linear-gradient(270deg, #0084FF 0%, #29F3FF 100%)
            .line
                width 100%
                display flex
                align-items center
                .line-bg
                    background-color #fff
                    border-radius 3px
                    margin-right 10px
                    display inline-block
                    width 85%
                    .line-color
                        background linear-gradient(270deg, #0084FF 0%, #29F3FF 100%)
                .num
                    color #1AC9FF
                    font-size 14px
.leaseArea
    display flex
    height 212px
    div
        &:not(:last-of-type)
            border-right-width 0
    .oneCol
        border 1px solid #fff
        width 240px
        display flex
        flex-flow column
        div
            text-align center
            color #fff
            flex 1 0 auto
            display flex
            flex-flow column
            justify-content center
            &:not(:last-of-type)
                border-bottom 1px solid #fff
            p
                font-size 12px
                margin-bottom 0
    .twoCol
        @extends .oneCol
        width 230px
    .threeCol
        @extends .oneCol
        width 220px
    .fourCol
        @extends .oneCol
        width 190px
    .fiveCol
        @extends .oneCol
        width 110px
        background linear-gradient(90deg, #1AC9FF 0%, rgba(161, 233, 255, 0) 100%)
        opacity 0.5
        border none

.bg-color-purple
    background-color #623AC7
.bg-color-blue
    background-color #008BDB
.bg-color-green
    background-color #1BC2B9
.bg-color-red
    background-color #C44343
.bg-color-yellow
    background-color #E88E3F


.bottom-right-p
    position absolute;
    bottom 36px
    right 37px
#tenant
    height 157px
    width 80%
#staff
    height 220px
#vacancy-rate
    height 220px
.personal-type-panel
    overflow hidden
    background rgba(7, 65, 182, 0.25)
    box-shadow inset 0 0 50px  0 rgba(0, 255, 255, .2)
    padding 20px
    border-right 1px solid #1ac9ff
.buildingUsers
    width 400px
    height 610px
.lease-situation
    width 400px
    height 240px
    margin-bottom 20px
    .lease-situation-chart
        display flex
        align-items center
        .info
            .room
                text-align center
                .num
                    color #FFD301
                    font-size 14px
                    margin-bottom 0
                .text
                    color #1987F1
                    font-size 14px
            .area
                @extends .room

.building-rental-rate
    width 400px
    height 350px
.enterprise-staff
    width 400px
    height 300px
.lease-area
    width 970px
    height 300px
 .videoMp4
    width 100%
</style>
